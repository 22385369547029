export enum Breakpoints {
  NONE = 0,
  XXXS = 250,
  XXS = 375,
  XS = 480,
  SM = 650,
  MD = 768,
  LG = 1024,
  XL = 1280,
}
